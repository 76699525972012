<template>
    <div class="main-container">
      <h5 class="pageTitle">교관정보 수정/삭제</h5>
  
      <div class="form-container">
        <div class="form-group">
          <label for="name">이름</label>
          <input v-model="form.name" type="text" class="input-box" placeholder="이름을 입력하세요" />
        </div>
  
        <div class="form-group">
          <label for="no">자격번호</label>
          <input v-model="form.no" type="text" class="input-box" placeholder="자격번호를 입력하세요" />
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateInstructor">수정</button>
          <button class="button-delete" @click="deleteInstructor">삭제</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from '@/axios';
  import Swal from 'sweetalert2';
  
  export default {
    name: 'InstructorUpdateDelete',
    data() {
      return {
        form: {
          uuid: '',
          name: '',
          no: '',
        },
      };
    },
    created() {
      const parsedData = JSON.parse(this.$route.query.data);
      this.form = { ...parsedData };
    },
    methods: {
      async updateInstructor() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem('token'));
          const token = tokenData?.access_token || '';
  
          await axios.put(`/master/instructor/${this.form.uuid}`, this.form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          Swal.fire('완료', '교관 정보가 수정되었습니다.', 'success');
          this.goToList();
        } catch (error) {
          console.error('교관 수정 실패:', error);
          Swal.fire('오류', '수정 중 오류가 발생했습니다.', 'error');
        }
      },
      async deleteInstructor() {
        const confirmResult = await Swal.fire({
          title: '삭제 확인',
          text: '정말로 이 교관 정보를 삭제하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
        });
  
        if (!confirmResult.isConfirmed) return;
  
        try {
          const tokenData = JSON.parse(sessionStorage.getItem('token'));
          const token = tokenData?.access_token || '';
  
          await axios.delete(`/master/instructor/${this.form.uuid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          Swal.fire('완료', '교관 정보가 삭제되었습니다.', 'success');
          this.goToList();
        } catch (error) {
          console.error('교관 삭제 실패:', error);
          Swal.fire('오류', '삭제 중 오류가 발생했습니다.', 'error');
        }
      },
      goToList() {
        this.$router.push({ name: 'master-instructorRead' });
      },
    },
  };
  </script>
  <style scoped>
  /* 동일한 스타일 유지 */
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .input-box,
  .select-box {
    /* flex: 1; */
    width: 600px;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    
  }
  
  .input-select {
    width: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .button-container {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-update {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-delete {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  </style>
      
  
  